import React, { useEffect } from "react"
import { useReactiveVar } from "@apollo/client"
import { userGlobalState } from "@temp/@next/globalStates/User/userGlobalState"
import { trackEvent } from "@temp/core/utils"
import { getDBIdFromGraphqlId } from "@temp/core/utils"

export const SetUserProfileGTM: React.FC = () => {
  const { user } = useReactiveVar(userGlobalState)
  const sendUserData = () => {
    if (user) {
      const { id, inferredEmail, inferredFirstName, inferredLastName, inferredPhone } = user
      const eventValue = {
        userId: getDBIdFromGraphqlId(id),
        userName: `${inferredFirstName} ${inferredLastName}`,
        email: inferredEmail,
        phone: inferredPhone,
      }
      trackEvent({
        eventName: "set_user_profile",
        eventValue,
        sendToDataLayer: true,
      })
    }
  }

  useEffect(() => {
    sendUserData()
  }, [user?.id])

  return null
}
